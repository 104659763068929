import React, { useState, useEffect } from 'react';
import { getMindentityDataURL } from '@/shared/lib/Mindentity.script.mjs';

import styles from './Logo.module.scss';
import Container from '../Container/Container';
import { useData } from '@/shared/providers/DataProvider';
import { useRouter } from 'next/router';

const Logo: React.FC<any> = () => {
    const [isClient, setIsClient] = useState(false);
    const [title, setTitle] = useState('Mindnow');
    const [color, setColor] = useState('#fff');
    const [whitespace, setWhitespace] = useState(100);
    const { pageMainColor, pageName } = useData();
    const router = useRouter();
    const name = router.asPath.split('/')[1] ? router.asPath.split('/')[1] : pageName ?? 'Prozess';

    useEffect(() => {
        setIsClient(true);
        setTimeout(() => animateWhitespace(100, 30, 500), 500);
        setTimeout(() => animateWhitespace(30, 100, 500), 1500);
        setTimeout(() => {
            setTitle(pageName.split('#')[0]);
            const pageColor = getComputedStyle(document.body).getPropertyValue(`--${pageMainColor}`);
            setColor(pageColor);
        }, 2050);
        setTimeout(() => animateWhitespace(100, 30, 500), 2100);
    }, [name]); // eslint-disable-line

    function animateWhitespace(start: number, end: number, duration: number) {
        let startTimestamp: any = null;
        const step = (timestamp: any) => {
            if (!startTimestamp) startTimestamp = timestamp;
            const progress = Math.min((timestamp - startTimestamp) / duration, 1);
            setWhitespace(Math.floor(progress * (end - start) + start));
            if (progress < 1) {
                window.requestAnimationFrame(step);
            }
        };
        window.requestAnimationFrame(step);
    }

    const options = {
        margin: 0,
        mode: 'string',
        input: title,
        foregroundColor: color,
        backgroundColor: '#0D0C0B',
        transparent: true,
        whiteSpace: whitespace,
        gap: 5,
    };

    return (
        <Container code="standard" className={{ wrap: styles.root }}>
            <div className={styles.wrapper}>
                {isClient && (
                    <picture>
                        <img src={getMindentityDataURL(options as any)} alt="Logo" className={styles.logo} />
                    </picture>
                )}
            </div>
        </Container>
    );
};

export default Logo;
